import "../stylesheets/Banner.css";
import { Carousel } from "react-bootstrap";
import { useState } from "react";
import BannerButton from "./Buttons/BannerButton";

const catA = require("../images/cat3.jpg");
const catB = require("../images/cat4.jpg");
const catC = require("../images/cat1.jpg");
const catD = require("../images/cat2.jpg");

const Banner = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel
      fade
      indicators={false}
      interval="3500"
      activeIndex={index}
      onSelect={handleSelect}
      className="banner"
    >
      <Carousel.Item>
        <img className="banner-img" src={catA} alt="First slide" />
        <Carousel.Caption>
          <BannerButton />
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="banner-img" src={catB} alt="Second slide" />
        <Carousel.Caption>
          <BannerButton />
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="banner-img" src={catC} alt="Third slide" />
        <Carousel.Caption>
          <BannerButton />
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="banner-img" src={catD} alt="Fourth slide" />
        <Carousel.Caption>
          <BannerButton />
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};
export default Banner;
